import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { App } from "./App";

// MSAL configuration
const configuration: Configuration = {
  auth: {
    clientId: "3a202d6d-977c-4917-90cc-801a4af33acb",
    authority:
      "https://login.microsoftonline.com/40506da0-ab9e-4fc5-ace5-e0e2c3a32379",
    redirectUri: window.location.origin,
  },
};

const pca = new PublicClientApplication(configuration);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <div className="min-h-screen min-w-full bg-gray-400">
        <App />
      </div>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
