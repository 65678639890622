import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Home } from "./pages/home";
import { Ansatt } from "./pages/ansatt";
import { Bravo } from "./pages/bravo";

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/ansatt" element={<Ansatt />} />
        <Route path="/bravo" element={<Bravo />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};
