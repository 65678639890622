import { PowerBi } from "../../Components/powerBi/powerBi";

export const Bravo = () => {
  return (
    <div className="flex flex-col h-screen">
      <div className="flex-grow bg-gray-300">
        <div className="flex justify-center items-center h-full">
          <PowerBi pageName="ReportSection" />
        </div>
      </div>
    </div>
  );
};
