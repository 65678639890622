import React, { useState, useEffect } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useMsal } from "@azure/msal-react";
import "./style.css";

export const PowerBi = ({ pageName }: { pageName: string }) => {
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    const getToken = async () => {
      await instance.initialize();
      await instance
        .acquireTokenSilent({
          scopes: ["https://analysis.windows.net/powerbi/api/.default"],
          account: accounts[0],
        })
        .then((res) => setAccessToken(res.accessToken))
        .catch((error) => console.error("Failed to acquire token:", error));
    };

    getToken();
  }, [instance, accounts]);

  const isMobile = window.innerWidth <= 768;

  if (!accessToken) return <div>Loading..</div>;

  return (
    <PowerBIEmbed
      embedConfig={{
        type: "report",
        id: "2f64cdf2-02da-44df-aba0-a4bbc67dd1d3",
        embedUrl:
          "https://app.powerbi.com/reportEmbed?reportId=2f64cdf2-02da-44df-aba0-a4bbc67dd1d3",
        accessToken: accessToken,
        tokenType: models.TokenType.Aad,
        pageName: pageName,
        settings: {
          layoutType: isMobile
            ? models.LayoutType.MobilePortrait
            : models.LayoutType.Master, // Change to your default layout type
          panes: {
            filters: {
              expanded: false,
              visible: false,
            },
          },
          background: models.BackgroundType.Transparent,
          navContentPaneEnabled: false,
        },
      }}
      eventHandlers={
        new Map([
          [
            "loaded",
            function () {
              console.log("Report loaded");
            },
          ],
          [
            "rendered",
            function () {
              console.log("Report rendered");
            },
          ],
          [
            "error",
            function (event) {
              console.log(event?.detail);
            },
          ],
          ["visualClicked", () => console.log("visual clicked")],
          ["pageChanged", (event) => console.log(event)],
        ])
      }
      cssClassName={"Embed-container"}
    />
  );
};
