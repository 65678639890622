import { PowerBi } from "../../Components/powerBi/powerBi";

export const Ansatt = () => {
  return (
    <div className="flex flex-col h-screen">
      <div className="flex-grow bg-gray-300">
        <div className="flex justify-center items-center h-full">
          <PowerBi pageName="ReportSection00123d9db01d41b637a2" />
        </div>
      </div>
    </div>
  );
};
