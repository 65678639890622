import { Router } from "./Router";
import { Nav } from "./Components/navbar/nav";
import { useMsal } from "@azure/msal-react";
import logo from "./images/BRAVOANSATT.png";

export const App = () => {
  const { instance, accounts, inProgress } = useMsal();
  if (accounts.length > 0) {
    return (
      <>
        <Nav userName={accounts[0].name?.split(" ", 1).toString()} />
        <div className="min-h-screen min-w-full bg-gray-400 pt-16">
          <Router />
        </div>
      </>
    );
  } else if (inProgress === "login") {
    return <span className="text-center">Pålogging pågår!</span>;
  } else {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <img src={logo} className="h-10 mb-10" alt="BravoAnsatt LOGO" />
        <span className="mb-4 text-lg text-white">
          Ingen brukere er for øyeblikket pålogget
        </span>
        <button
          className="px-4 py-2 bg-blue-800 text-white rounded-md transition-colors duration-300 ease-in-out hover:bg-blue-600"
          onClick={() => instance.loginPopup()}
        >
          Logg inn
        </button>
      </div>
    );
  }
};
