export const Home = () => {
  return (
    <div className="flex flex-col h-screen">
      <div className="flex-grow bg-gray-200">
        <div className="flex justify-center items-center h-full">
          <a
            href="/bravo"
            className="w-64 h-32 p-4 text-center bg-white shadow-md flex justify-center items-center hover:bg-gray-100 transition duration-300 ease-in-out rounded-md"
          >
            <span className="text-lg font-semibold text-gray-800">
              BravoNET
            </span>
          </a>
        </div>
      </div>

      <div className="flex-grow bg-gray-300">
        <div className="flex justify-center items-center h-full">
          <a
            href="/ansatt"
            className="w-64 h-32 p-4 text-center bg-white shadow-md flex justify-center items-center hover:bg-gray-100 transition duration-300 ease-in-out rounded-md"
          >
            <span className="text-lg font-semibold text-gray-800">Ansatt</span>
          </a>
        </div>
      </div>
    </div>
  );
};
