import { useState } from "react";
import logo from "../../images/BRAVOANSATT.png";

export const Nav = ({ userName = "User" }: { userName?: string }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const path = window.location.pathname;

  const activeLinkClass = "text-orange-400";
  const menuClass =
    "block py-2 px-3 rounded md:bg-transparent md:p-0 hover:text-orange-500";

  const menuItems = (
    <>
      {" "}
      <li>
        <a
          href="/"
          className={`${menuClass} ${
            path === "/" ? activeLinkClass : "text-white"
          }`}
          aria-current="page"
        >
          Hjem
        </a>
      </li>
      <li>
        <a
          href="/bravo"
          className={`${menuClass} ${
            path === "/bravo" ? activeLinkClass : "text-white"
          }`}
        >
          BravoNET
        </a>
      </li>
      <li>
        <a
          href="/ansatt"
          className={`${menuClass} ${
            path === "/ansatt" ? activeLinkClass : "text-white"
          }`}
        >
          Ansatt
        </a>
      </li>
    </>
  );

  return (
    <nav className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600 pt-1">
      <div className="max-w-screen-xl flex items-center justify-between mx-auto p-4 relative">
        {/* Logo (smaller on desktop) */}
        <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={logo} className="h-5 md:h-6" alt="BravoAnsatt LOGO" />
        </a>
        {/* Menu items (hidden on mobile) */}
        <div className="hidden md:flex flex-grow justify-center">
          <ul className="flex flex-col md:flex-row items-center md:items-stretch justify-center md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0">
            {menuItems}
          </ul>
        </div>
        {/* Hei, username (larger on mobile) */}
        <span className="self-center text-base md:text-lg font-semibold whitespace-nowrap dark:text-white">
          Hei, {userName}!
        </span>
        {/* Hamburger button (visible on mobile) */}
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden bg-white shadow-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:bg-gray-700 dark:hover:bg-gray-800 dark:focus:ring-gray-600"
        >
          <span className="sr-only">Toggle mobile menu</span>
          {isMenuOpen ? (
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          )}
        </button>
      </div>
      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="fixed top-0 right-0 mt-16 z-20 w-full">
          <div className="bg-white dark:bg-gray-900 justify-center">
            <ul className="flex flex-col items-end mt-2">{menuItems}</ul>
          </div>
        </div>
      )}
    </nav>
  );
};
